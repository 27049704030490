// Instructions.js
import React from "react";

function Instructions() {
    const handleOpenModal = (e) => {
        if (typeof window.openModal === "function") {
            window.openModal(e.currentTarget);
        } else {
            console.error("Функция openModal не найдена.");
        }
    };

    const copyCodeToClipboard = (code) => {
        navigator.clipboard.writeText(code).then(
            () => {
                // alert("Код скопирован в буфер обмена");
            },
            (err) => {
                console.error("Не удалось скопировать код: ", err);
            }
        );
    };

    return (
        <div className="instructions">
            <div className="step">
                <h2>Шаг 1: Вставьте код CSS</h2>
                <p>
                    Разместите на каждой странице сайта, где необходимо
                    открывать виджет перед закрывающимся тегом head строку:
                </p>
                <div className="code-container">
                    <pre className="code-block">
                        <code>
                            &lt;link rel="stylesheet"
                            href="https://widget.ticketok.by/1.1.0/main.min.css"/&gt;
                        </code>
                    </pre>
                    <button
                        className="copy-button"
                        onClick={() =>
                            copyCodeToClipboard(
                                '<link rel="stylesheet" href="https://widget.ticketok.by/1.1.0/main.min.css" />'
                            )
                        }>
                        Копировать
                    </button>
                </div>
            </div>
            <div className="step">
                <h2>Шаг 2: Вставьте код JS</h2>
                <p>
                    Разместите на каждой странице сайта, где необходимо
                    открывать виджет перед закрывающимся тегом body строку:
                </p>
                <div className="code-container">
                    <pre className="code-block">
                        <code>
                            &lt;script
                            src="https://js.bepaid.by/widget/be_gateway.js"&gt;&lt;/script&gt;
                            <br />
                            &lt;script
                            src="https://widget.ticketok.by/1.1.0/main.min.js"&gt;&lt;/script&gt;
                        </code>
                    </pre>
                    <button
                        className="copy-button"
                        onClick={() =>
                            copyCodeToClipboard(
                                '<script type="text/javascript" src="https://js.bepaid.by/widget/be_gateway.js"></script><script type="text/javascript" src=https://widget.ticketok.by/1.1.0/main.min.js"></script>'
                            )
                        }>
                        Копировать
                    </button>
                </div>
            </div>
            <div className="step">
                <h2>Шаг 3: Настройте виджет</h2>
                <p>
                    В коде страницы, где необходимо вывести кнопку на открытие
                    модального окна, вставьте строку:
                </p>
                <div className="code-container">
                    <pre className="code-block">
                        <code>
                            &lt;button data-ticket-id="XXX" data-event-id="YYY"
                            onclick="openModal(this)"&gt;Купить&lt;/button&gt;
                        </code>
                    </pre>
                    <button
                        className="copy-button"
                        onClick={() =>
                            copyCodeToClipboard(
                                '<button data-ticket-id="XXX" data-event-id="YYY" onclick="openModal(this)">Купить</button>'
                            )
                        }>
                        Копировать
                    </button>
                </div>
                <p>
                    где XXX - уникальный id категории билета, YYY - уникальный
                    id мероприятия (запросите у менеджера)
                </p>
                <p>
                    <b>для тестового запуска установите data-ticket-id = 4 и data-event-id = 4</b>
                </p>
            </div>
            <div className="step">
                <h2>Шаг 4: Проверьте работоспособность</h2>
                <p>
                    На этапе ввода данных банковской карты - укажите карту <b>4200 0000 0000 0000</b> со сроком действия <b>12/24</b>, CVC код <b>123</b>, имя может быть любым.
                </p>
                <p>
                    В случае возникновения проблем или вопросов можно обратиться по адресу <a href="mailto:ddm@vivabraslav.by">ddm@vivabraslav.by</a>
                </p>
                <p>Результат правильной интеграции должен быть следующим:</p>
                <button
                    data-ticket-id="4"
                    data-event-id="4"
                    onClick={handleOpenModal} // Используем обертку для вызова openModal
                >
                    Купить
                </button>
            </div>
        </div>
    );
}

export default Instructions;