// App.js
import React from 'react';
import Instructions from './Instructions';
import './App.css'; // Предполагаем, что стили определены в App.css

function App() {
  return (
    <div className="container">
      <h1 className='title'>Как установить виджет на страницу с билетами</h1>
      <Instructions />
    </div>
  );
}

export default App;
